<template>
  <div>
    <Header :showInput="true" />
    <Intro-body />
    <Footer v-if="getSelectedPages.length" />
    <div v-if="authProcessing" class="start-window">
      <div class="b21">{{ $t('USR_SAV') }}...</div>
    </div>
  </div>
</template>

<script>
import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";
import { ADMIN_SET } from "../../store/actions/admin";
import {
  INTRO_SET_PERIOD,
  INTRO_SET_PAGES,
  INTRO_DROP_SELECTED,
  INTRO_SET_SELECTED_ACCESS,
} from "../../store/actions/intro";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import IntroBody from "./components/Intro-body";
import { mapGetters, mapMutations, mapActions } from "vuex";
import tokenMixin from '@/mixins/tokenMixin';

export default {
  name: "Intro",
  mixins: [tokenMixin],
  components: {
    Header,
    IntroBody,
    Footer,
  },
  data() {
    return {
      // authProcessing is now handled by the mixin
    };
  },
  computed: {
    ...mapGetters([
      "getPeriod",
      "getPages",
      "getProfile",
      "getAllowedAccesses",
      "getSelectedPages",
      "isPagesLoaded",
      "getAddedHashes",
    ]),
    locale() {
      return this.$locale.INTRO.NOTIFICATIONS;
    },
    countPagesInAnalize() {
      return this.getAddedHashes.length;
    },
  },
  watch: {
    countPagesInAnalize(newCount, oldCount) {
      if (oldCount > newCount) {
        let title = document.title;
        let interval = setInterval(() => {
          const notification = this.$t('INTRO_NOTIFICATIONS_NOTIFICATION_TITLE');
          if (document.title === notification) document.title = title;
          else document.title = notification;
        }, 700);
        setTimeout(() => {
          clearInterval(interval);
          document.title = title;
        }, 5000);
      }
    },
  },
  methods: {
    ...mapActions([INTRO_SET_PAGES, AUTH_SOCIAL_TOKENS]),
    ...mapMutations([
      INTRO_SET_PERIOD,
      INTRO_DROP_SELECTED,
      INTRO_SET_SELECTED_ACCESS,
    ]),
    setStartedPeriod() {
      if (!this.getPeriod) {
        const period = {
          start: moment().subtract(30, "days").format("YYYY-MM-DD"),
          end: moment().subtract(1, "days").format("YYYY-MM-DD"),
        };
        this.INTRO_SET_PERIOD(period);
      }
    },
    afterSaveAction() {
      this.$router.push('/main');
    },
  },
  async created() {
    if (!this.$store.getters.isAccessLoad) {
      await this.$store.dispatch(ADMIN_SET);
    }
    this.INTRO_SET_SELECTED_ACCESS([...this.getAllowedAccesses]);
  },
  async mounted() {
    this.setStartedPeriod();
    this.INTRO_DROP_SELECTED();
    if (!this.isPagesLoaded) {
      this.INTRO_SET_PAGES();
    }
    this.parseToken();
  },
};
</script>