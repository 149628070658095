// src/mixins/tokenMixin.js

import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";

import module from '@/models/AxiosModule'
import { mapMutations } from "vuex";
import { INTRO_SET_ADD_PAGE_VISIBLE } from "@/store/actions/intro";
const axios = module.Axios

export default {
  data() {
    return {
      authProcessing: false,
    };
  },
  methods: {
    ...mapMutations([INTRO_SET_ADD_PAGE_VISIBLE]),
    isVkPageTokens(url) {
      const match = {};
      [...url.matchAll(/access_token_([0-9]*)=([0-9a-zA-Z._-]*)/gm)].forEach((i) => {
        const [_, key, value] = i;
        match[key] = value;
      });
      if (Object.keys(match).length) return match;
      return undefined;
    },
    isVk(url) {
      const match = url.match(
        "access_token=([-A-Za-z0-9._]*)&expires_in=([0-9]*)&user_id=([0-9]*)"
      );
      if (match && match.length === 4) {
        this.$alert.show({
          message: this.$t('SUCCESS_AUTH', { social: 'VK' }),
          type: 'global',
          color: '',
        });
        this.INTRO_SET_ADD_PAGE_VISIBLE(true);
        return { vk_token: match[1] };
      }
      return false;
    },
    isFb(url) {
      const match = url.match(
        `access_token=([A-Za-z0-9]*)&([\\S]*)&state=(facebook)`
      );
      if (match && match.length === 4) {
        this.$alert.show({
          message: this.$t('SUCCESS_AUTH', { social: 'FB' }),
          type: 'global',
          color: '',
        });
        this.INTRO_SET_ADD_PAGE_VISIBLE(true);
        return { fb_token: match[1] };
      }
      return false;
    },
    isIg(url) {
      const match = url.match(
        "access_token=([A-Za-z0-9]*)&([\\S]*)&state=(instagram)"
      );
      if (match && match.length === 4) {
        this.$alert.show({
          message: this.$t('SUCCESS_AUTH', { social: 'IG' }),
          type: 'global',
          color: '',
        });       
        this.INTRO_SET_ADD_PAGE_VISIBLE(true); 
        return { ig_token: match[1] };
      }
      return false;
    },
    saveSocialTokens(url) {
      const token = this.isFb(url) || this.isVk(url) || this.isIg(url);
      if (token) {
        this.authProcessing = true;
        axios.post("social-token", { token }).then(() => {
          this.$store.dispatch(AUTH_SOCIAL_TOKENS);
          localStorage.setItem(
            "message",
            JSON.stringify({ command: "auth", data: token })
          );
          this.authProcessing = false;
          this.afterSaveAction();
        });
      }
    },
    saveVkPageTokens(tokens) {
      this.authProcessing = true;
      axios.post("vk-page-tokens", { tokens }).then(() => {
        this.authProcessing = false;
        this.afterSaveAction();
      });
    },
    parseToken() {
      const url = window.location.href;
      if (url.includes('access_token') || url.includes('state')) {
        const pageTokens = this.isVkPageTokens(url);
        if (pageTokens) {
          this.saveVkPageTokens(pageTokens);
        } else {
          this.saveSocialTokens(url);
        }
      }
    },
    afterSaveAction() {
      // Default action does nothing; override in components if needed
    },
  },
};