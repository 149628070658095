<template>
  <div>
    <Intro-start v-if="getPages.length == 0 && isPagesLoaded"></Intro-start>
    <Intro-main v-if="getPages.length && isPagesLoaded"></Intro-main>
    <loading :active="!isPagesLoaded" :is-full-page="false"></loading>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import IntroStart from "./Intro-start";
import IntroList from "./Intro-list";
import IntroMain from "./Intro-main";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Intro-body",
  components: {
    IntroStart,
    Loading,
    IntroList,
    IntroMain,
  },
  computed: {
    ...mapGetters([
      "isBySocial",
      "getPeriod",
      "getPages",
      "getAllowedAccesses",
      "getProfile",
      "getAddedHashes",
      "getSelectedPages",
      "isPagesLoaded",
      "getDisabledDates",
      "getPagesByAccessVisible",
      "getPagesBySocial",
      "getSelectedAccessAcount",
      "isAddPageVisible",
      "isAccessManagerVisible",
      "isAutoReportsSettingsVisible",
    ]),
  },
  methods: {},
  mounted() {
  },
};
</script>
