<template>
  <tr @click="selectPage(page)" :class="classLine">
    <td>
      <input
        type="checkbox"
        name="checkbox"
        :id="page.hash_id"
        class="inp-chk"
        :checked="page.isSelected"
        @click.stop
      />
      <label for="m5" class="inp-chk"></label>
      <img :src="page.owner_avatar" alt class="list-logo" @click.prevent.stop="analyzePage(page)"/>
      <span @click.prevent.stop="analyzePage(page)" class="list-page-name">{{page.owner_name}}</span>
      <span v-if="page.status === 'error'" @click.prevent.stop="reanalyzePage(page)" class="reanalyze-button">
        <i class="bi-arrow-clockwise"></i>
      </span>
      <scroll-loader
        class="intro-line-loader"
        :loader-method="()=> {}"
        :loader-enable="page.status == 'added'"
      ></scroll-loader>
    </td>
    <td :class="classFolowers">{{formatNum(page.followers)}} <i v-if="iconVisible && page.followers" :class="iconClass"></i></td>
    <td :class="classGrowth">{{formatNum(page.growth)}}</td>
    <td>{{formatNum(page.reach_avg)}}</td>
    <td>{{formatNum(page.er)}}</td>
    <td v-if="!social">
      <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
    </td>
    <td>
      <a target="_blank" :href="page.owner_url" class="ext-link" @click.stop>
        <i class="bi-box-arrow-up-right"></i>
      </a>
    </td>
    <td>
      <span v-if="page.status == 'finished'" class="close" @click.stop="openDeleteConfirm($event, page.hash_id)">
        <i class="bi-x-lg"></i>
      </span>
      <span
        v-if="page.status == 'paused' || page.status == 'deleted'"
        class="close"
        @click.stop="openRestoreConfirm($event, page.hash_id)"
      >
        <i class="bi-x-lg"></i>
      </span>
    </td>
  </tr>
</template>

<script>
import pageUtils from "@/models/page-utils";
import ClickOutside from 'vue-click-outside';

import { WALL_HASH } from "../../../store/actions/wall";
import {
  INTRO_SET_SELECTED,
  INTRO_UPDATE_ADDED,
  INTRO_SET_DELETED,
  INTRO_DROP_SELECTED,
  INTRO_SET_DELETE_CONFIRM,
  INTRO_SET_RESTORE_CONFIRM,
  INTRO_REANALYZE_PAGE,
} from "../../../store/actions/intro";
import { mapGetters, mapMutations, mapActions } from "vuex";
import module from '@/models/AxiosModule'

export default {
  name: "intro-line",
  components: {},
  props: {
    page: {
      type: Object,
      required: true,
    },
    social: {
      type: String,
    },
  },
  data() {
    return {
      showDeleteConfirm: null
    };
  },
  computed: {
    ...mapGetters([
      "getPeriod", 
      "getDeleteConfirmId", 
      "getRestoreConfirmId",
      "getProfile",
      "getPages",
      "getAllowedAccesses"
    ]),
    classFolowers() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'positive' : intGrowth < 0 ? 'negative' : '';
      return "";
    },
    classGrowth() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'grw-pos' : intGrowth < 0 ? 'grw-neg' : '';
      return "";
    },
    iconVisible() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth !== 0;
    },
    iconClass() {
      const intGrowth = parseInt(this.page.growth);
      return intGrowth > 0 ? 'bi bi-arrow-up-right' : 'bi bi-arrow-down-right';
    },
    classLine() {
      if (this.page.status == "finished") return "";
      return "inactive";
    },
    canRestorePage() {
      if (!this.getPages) return false;
      
      // Check if this page belongs to the current user or another account
      if (this.page.email === this.getProfile.email) {
        // Current user's page - check against current user's limit
        const currentPages = this.getPages.filter(p => p.status !== 'deleted' && p.email === this.getProfile.email).length;
        return currentPages < this.getProfile.page_limit;
      } else {
        // Another account's page - check against that account's limit
        const pagesForThisAccount = this.getPages.filter(p => p.status !== 'deleted' && p.email === this.page.email).length;
        
        // Find the account in profiles with access if available
        const accountLimit = this.getAllowedAccesses.find(a => a.email === this.page.email)?.page_limit;
        
        // If we can't find the limit, assume it's sufficient (let server decide)
        if (!accountLimit) return true;
        
        return pagesForThisAccount < accountLimit;
      }
    }
  },
  methods: {
    ...mapMutations([
      INTRO_UPDATE_ADDED, 
      INTRO_SET_DELETED, 
      INTRO_DROP_SELECTED,
      INTRO_SET_DELETE_CONFIRM,
      INTRO_SET_RESTORE_CONFIRM,
    ]),
    ...mapActions([
      INTRO_SET_SELECTED,
      INTRO_REANALYZE_PAGE,
    ]),
    formatNum(num) {
      return pageUtils.formatNumbers(num);
    },
    analyzePage(page) {
      this.INTRO_DROP_SELECTED()
      this.INTRO_SET_SELECTED(page)
      this.$router.push({ path: '/dashboard' })
    },
    reanalyzePage(page) {
      this.INTRO_REANALYZE_PAGE({ hash_id: page.hash_id });
    },
    selectPage: function (page) {
      if (page.status != "finished") return;
      this.INTRO_SET_SELECTED(page);
    },
    hideDeleteConfirm() {
      this.showDeleteConfirm = null;
    },
    async confirmDelete(page) {
      await this.deletePage(page);
      this.hideDeleteConfirm();
    },
    async deletePage({ hash_id }) {
      const axios = module.Axios;
      await axios.post("delete-page", { hash_id });
      this.INTRO_SET_DELETED(hash_id);
      this.closeDeleteConfirm();
      this.$store.dispatch(
        'alerts/addAlert',
        {
          message: this.$t('PAGE_DELETE_SUCCESS'),
          duration: 5000,
          color: '',
        },
        { root: true }
      );
    },
    openRestoreConfirm(event, hash_id) {
      if (!this.canRestorePage) {
        this.$alert.show({
          message: this.$t('ALERT_PAGE_LIMIT_REACHED'),
          type: 'inline',
          color: '',
          duration: 5000,
        });
        return;
      }
      this.INTRO_SET_RESTORE_CONFIRM(hash_id);
      this.$nextTick(() => {
        this.positionConfirmationDialog(event);
      });
    },
    closeRestoreConfirm() {
      this.INTRO_SET_RESTORE_CONFIRM(null);
      this.removeConfirmationDialog();
    },
    async confirmRestore(page) {
      if (this.canRestorePage) {
        await this.restorePage(page);
        this.closeRestoreConfirm();
      }
    },
    async restorePage(page) {
      try {
        const axios = module.Axios;
        await axios.post("add-page", {
          pages: [{
            hash_id: page.hash_id,
            is_admin: page.is_admin,
            owner_avatar: page.owner_avatar,
            owner_domain: page.owner_domain,
            owner_id: page.owner_id,
            owner_name: page.owner_name,
            owner_type: page.owner_type,
            owner_url: page.owner_url,
            social: page.social,
          }],
          owner_email: page.email,
        });
        this.INTRO_UPDATE_ADDED(page.hash_id);
      } catch (error) {
        this.$alert.show({
          message: this.$t('ALERT_RESTORE_ERROR'),
          type: 'inline',
          color: 'red',
          duration: 5000,
        });
      }
    },
    openDeleteConfirm(event, hash_id) {
      this.INTRO_SET_DELETE_CONFIRM(hash_id);
      this.$nextTick(() => {
        this.positionConfirmationDialog(event);
      });
    },
    closeDeleteConfirm() {
      this.INTRO_SET_DELETE_CONFIRM(null);
      this.removeConfirmationDialog();
    },
    positionConfirmationDialog(event) {
      this.$nextTick(() => {
        const buttonRect = event.target.closest('.close').getBoundingClientRect();
        
        let container = document.getElementById('global-confirmation-container');
        if (!container) {
          container = document.createElement('div');
          container.id = 'global-confirmation-container';
          container.style.position = 'fixed';
          container.style.zIndex = '9999';
          container.style.pointerEvents = 'none';
          document.body.appendChild(container);
        }
        
        container.innerHTML = '';
        
        const dialog = document.createElement('div');
        dialog.className = 'delete-confirm';
        dialog.style.position = 'absolute';
        dialog.style.background = '#FFFFFF';
        dialog.style.boxShadow = '0px 10px 25px rgba(0, 0, 0, 0.08)';
        dialog.style.borderRadius = '8px';
        dialog.style.padding = '8px 10px';
        dialog.style.width = '220px';
        dialog.style.fontSize = '14px';
        dialog.style.color = '#182228';
        dialog.style.textAlign = 'center';
        dialog.style.cursor = 'auto';
        dialog.style.pointerEvents = 'auto';
        
        if (this.getDeleteConfirmId === this.page.hash_id) {
          dialog.innerHTML = `
            <p>${this.$t('DELETE_CONFIRM')}</p>
            <div class="delete-actions">
              <button class="btn-cancel">${this.$t('DELETE_CANCEL')}</button>
              <button class="btn-confirm">${this.$t('DELETE_CONFIRM_DELETE')}</button>
            </div>
          `;
          
          dialog.querySelector('.btn-cancel').addEventListener('click', (e) => {
            e.stopPropagation();
            this.closeDeleteConfirm();
          });
          
          dialog.querySelector('.btn-confirm').addEventListener('click', (e) => {
            e.stopPropagation();
            this.confirmDelete(this.page);
          });
        } else if (this.getRestoreConfirmId === this.page.hash_id) {
          dialog.innerHTML = `
            <p>${this.$t('RESTORE_CONFIRM')}</p>
            <div class="delete-actions">
              <button class="btn-cancel">${this.$t('DELETE_CANCEL')}</button>
              <button class="btn-confirm">${this.$t('RESTORE_CONFIRM_RESTORE')}</button>
            </div>
          `;
          
          dialog.querySelector('.btn-cancel').addEventListener('click', (e) => {
            e.stopPropagation();
            this.closeRestoreConfirm();
          });
          
          dialog.querySelector('.btn-confirm').addEventListener('click', (e) => {
            e.stopPropagation();
            this.confirmRestore(this.page);
          });
        }
        
        container.appendChild(dialog);
        
        container.style.top = `${buttonRect.bottom + 10}px`;
        container.style.left = `${buttonRect.right - 220}px`;
        
        const dialogRect = dialog.getBoundingClientRect();
        
        if (dialogRect.right > window.innerWidth) {
          container.style.left = `${window.innerWidth - dialogRect.width - 10}px`;
        }
        
        if (dialogRect.bottom > window.innerHeight) {
          container.style.top = `${buttonRect.top - dialogRect.height - 10}px`;
        }
        
        const handleClickOutside = (e) => {
          if (!dialog.contains(e.target) && !event.target.closest('.close').contains(e.target)) {
            if (this.getDeleteConfirmId === this.page.hash_id) {
              this.closeDeleteConfirm();
            } else if (this.getRestoreConfirmId === this.page.hash_id) {
              this.closeRestoreConfirm();
            }
            document.removeEventListener('click', handleClickOutside);
          }
        };
        
        setTimeout(() => {
          document.addEventListener('click', handleClickOutside);
        }, 0);
      });
    },
    removeConfirmationDialog() {
      const container = document.getElementById('global-confirmation-container');
      if (container) {
        container.innerHTML = '';
      }
    },
  },
  directives: {
    ClickOutside
  },
};
</script>

<style scoped>
.reanalyze-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
  color: #007bff;
  transition: color 0.2s ease;
}

.reanalyze-button:hover {
  color: #0056b3;
}

.reanalyze-button i {
  font-size: 14px;
}
</style>

