<template>
  <div>
    <span class="soc-sea" v-if="social">
      <span :class="`soc-ico soc-${social}`"></span>
      {{ socialName }}
    </span>

    <span class="soc-sea" v-if="email">
      <!-- <span :class="`soc-ico soc-${social}`"></span> -->
      {{ email }} ({{ pages.length }}/{{ getProfile?.pageLimit || 0 }})
    </span>
    <div class="p-list">
      <table>
        <thead>
          <tr>
            <th
              :class="classTableHead('name')"
              @click="toggleSort('name')"
            >
              {{ $t('INTRO_TABS_NAME') }}
            </th>
            <th
              :class="classTableHead('followers')"
              @click="toggleSort('followers')"
            >
              {{ $t('INTRO_TABS_FOLLOWERS') }}
            </th>
            <th
              :class="classTableHead('growth')"
              @click="toggleSort('growth')"
            >
              {{ $t('INTRO_TABS_GROWTH') }}
            </th>
            <th
              :class="classTableHead('reach')"
              @click="toggleSort('reach')"
            >
              {{ $t('INTRO_TABS_REACH') }}
            </th>
            <th 
                :class="classTableHead('er')" 
                @click="toggleSort('er')">
              {{ $t('INTRO_TABS_ER') }}, %
            </th>
            <th v-if="!social" class="nonsort">{{ $t('INTRO_TABS_SOCIAL') }}</th>
            <th class="nonsort">{{ $t('INTRO_TABS_LINK') }}</th>
            <th class="nonsort">{{ $t('INTRO_TABS_DELETE') }}</th>
          </tr>
        </thead>
        <tbody>
          <intro-line
            :page="page"
            :social="social"
            :key="index"
            v-for="(page, index) in sortedPages"
          ></intro-line>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import IntroLine from "./Intro-line";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "intro-list",
  components: {
    IntroLine,
  },
  props: {
    pages: {
      type: Array,
      required: true,
    },
    social: {
      type: String,
    },
    socialName: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  watch: {
    pages: {
      handler(newValue) {},
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getProfile']),
    sortedPages() {
      if (this.sort[this.sort.type] === 0) return this.pages;
      const params = {
        name: "owner_name",
        followers: "followers",
        growth: "growth",
        reach: "reach_avg",
        er: "er",
      };
      const sortBy = params[this.sort.type];
      const copyPages = JSON.parse(JSON.stringify(this.pages));
      return copyPages.sort((pageA, pageB) => {
        let a = pageA[sortBy];
        let b = pageB[sortBy];
        if (this.sort.type === 'name') {
          a = a ? a.toString() : '';
          b = b ? b.toString() : '';
          const compareResult = a.localeCompare(b, undefined, { sensitivity: 'accent' });
          if (this.sort[this.sort.type] === 1) {
            return -compareResult;
          } else if (this.sort[this.sort.type] === 2) {
            return compareResult;
          }
        } else {
          a = Number(a);
          b = Number(b);
          if (isNaN(a)) a = -Infinity;
          if (isNaN(b)) b = -Infinity;
          if (this.sort[this.sort.type] === 1) {
            return b - a;
          } else if (this.sort[this.sort.type] === 2) {
            return a - b;
          }
        }
        return 0;
      });
    },
  },
  mounted() {},
  methods: {
    toggleSort(type) {
      const current = this.sort[type];
      this.sort = {
        name: 0,
        followers: 0,
        growth: 0,
        reach: 0,
        er: 0,
        type,
        [type]: (current + 1) % 3,
      };
    },
    classTableHead(type) {
      const status = this.sort[type];
      if (status === 0) return "";
      if (status === 1) return "sort-by-bot down";
      if (status === 2) return "sort-by-bot up";
    },
  },
  data() {
    return {
        sort: {
        name: 0,
        followers: 0,
        growth: 0,
        reach: 0,
        er: 0,
        type: "name",
      },
    };
  },
};
</script>

<style>
</style>